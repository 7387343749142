import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="header">
          <div className="titulo">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria <br />
              <strong>
                com soluções para <br />
                sua empresa
              </strong>
            </h2>
          </div>
          <div className="descricao">
            <p>
              O Sebrae RS oferece ferramentas gratuitas para te ajudar a
              gerenciar melhor os seus negócios
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
        </div>

        <div className="content">
          <div className="col">
            <h3>ORIENTAÇÃO SOBRE CRÉDITO E FINANÇAS</h3>
            <p>
              Identificação do impacto no caixa e resultado da empresa de
              valores pagos a título de empréstimos e financiamentos. Importante
              para verificar se estão dentro da capacidade de pagamento da
              empresa. Também serão identificadas outras necessidades em relação
              a finanças.
            </p>
            <br />
            <a
              className="btn"
              href="https://sebraers.com.br/destaque/consultoria-em-gestao/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acesse aqui
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
